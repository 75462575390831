<template>
    <div> 
        <v-card   :elevation="appartment.appartment_elevation" class="my-2 ">
         <v-row class="py-0">
             <v-col class="py-0 my-0" cols="12">
                <v-carousel class="my-0 " height="24rem"  :show-arrows="false">
      <v-carousel-item class="carr" v-for="(item,i) in appartment.appartment_images" :key="i" :src="item.src" :href="appartment.appartment_air_bnb_link" target="_Blank"></v-carousel-item>
    </v-carousel>
             </v-col> <v-col cols="12" :href="appartment.appartment_air_bnb_link" target="_Blank" class="px-8"  style="height:18rem">
                <v-card-title >
                {{appartment.appartment_title}}
            </v-card-title>
            <v-card-text class="grey--text text-left"> 
                {{appartment.appartment_description}}
            </v-card-text>
           
             </v-col>
         </v-row>
           
         <v-card-actions  class="px-8">
             <v-btn fab large right absolute  :href="appartment.appartment_air_bnb_link" target="_Blank" color="#FF585D">
                <Icon style="color:#FFFFFF; font-size: 27px" large icon="fa-brands:airbnb" />

             </v-btn>

         </v-card-actions>
    <div :href="appartment.appartment_air_bnb_link" target="_Blank">
     
    </div>
           
            
          
        </v-card>
    </div>
</template>
<script>
import { Icon } from '@iconify/vue2';

export default {
    name: 'appartment',
    props: {
        appartment: Object
    },
    components: {
		Icon,
	},
 // do install package
}
</script>
<style>
.Card-Shadow-1{
    box-shadow: 3px 3px white, -1em 0 0.4em black;
}
.carr{
    border:5px;
}
</style>