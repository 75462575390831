import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    

      theme: {
        dark: false,
        
        themes: {
            light: {
                primary: "#08090A",
                secondary: "#EAF4D3",
                accent: "#82B1FF",
                error: "#cb152b",
                info: "#E9DC80",
                success: "#32965D",
                warning: "#ED254E",
                grey: "#808488",
                gold:'#BE913C',
                white:'#EAF4D3'
              },
              dark: {
                primary: "#000000",
                secondary: "#203F5E",
                accent: "#82B1FF",
                error: "#FF5252",
                info: "#E9DC80",
                success: "#00ab66",
                warning: "#FFC107",
                gold:'#BE913C'
      
              }
        }
      }
});
