<template>
    <div>
        <v-card style="background-color:rgba(255,255,255,0.63)">
            <v-img height="12rem" width="24rem" :src="box.box_img_url">

            </v-img>
            <v-card-text>
               <span class="text-center black--text font-size h2" >{{box.box_title}}</span> 
            </v-card-text>
        
  
         
        </v-card>
    </div>
</template>
<script>
export default {
    name: 'horse-box',
    props: {
        box: Object
    }
}
</script>