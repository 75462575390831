<template>
    <v-row >
        <v-col cols="12" md="6" class="text-center my-auto">
            <v-card-text class="white--text text-left">
                <h1 class="gold--text text-left font-weight-thin">A TIMELESS HERITAGE</h1>
                <br />
                <br />Built in 1061, Chateau de la Riviere is renowned to offer visitors a pristine environment top relax and indulge in
                exquisite horse back riding. It has hosted influential people such as Napoleon Bonaparte and his horses. Now
                almost 1000 years since then , the entire property has been meticulously renovated preserving it’s classical origins
            </v-card-text>
        </v-col>
        <v-col cols="12" md="6" class="py-0">
            <v-img height="81vh"  :src="parallax1"></v-img>
        </v-col>
    </v-row>
</template>
<script>
import parallax1 from '@/assets/20160410_1624.jpg'

export default {
    name: 'chateau',
    data() {
        return {
            parallax1: parallax1
        }
    }
}
</script>