<template>
  <v-container>
    <v-row class="text-center" justify="center"> 
      <v-col class="my-5" cols="12">
        <h2 class="gold--text">Accommodation</h2>
      </v-col>

      <v-col class="my-auto" v-for="appart in appartments" :key="appart.appartment_title" cols="12" sm="6" md="4">
        <appartment :appartment="appart"></appartment>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import parallax from '@/assets/parallax.jpg'
//import villate from '@/assets/villate.jpeg'
//import villate1 from '@/assets/villate1.jpeg'
//import villate3 from '@/assets/vilatte3.jpeg'
//import villate2 from '@/assets/appaert7.jpeg'
import Appartment from './Appartment.vue'
export default {
  name: 'appartments-for-rent',
  components: {
    Appartment
  },

  data: () => ({
    parallax: parallax,
    appartments: [{
        appartment_title: 'Maison « golfo »',
        appartment_images: [{
          src: "https://a0.muscache.com/im/pictures/miso/Hosting-50504072/original/f7f8e34f-7e9c-4029-89cb-7ccacecc3d10.jpeg?im_w=1200"
        }, {
          src: "https://a0.muscache.com/im/pictures/miso/Hosting-50504072/original/1bd413a8-85fb-4dca-b549-75c40da96cb3.jpeg?im_w=720"
        }, { 
          src: "https://a0.muscache.com/im/pictures/miso/Hosting-50504072/original/2ab1f489-2eea-4eb4-af48-4d276ad48048.jpeg?im_w=720" 
        },{ 
          src: "https://a0.muscache.com/im/pictures/miso/Hosting-50504072/original/7bfdf44f-1b99-45bf-92bb-5960d1b15474.jpeg?im_w=720" 
        },{ 
          src: "https://a0.muscache.com/im/pictures/miso/Hosting-50504072/original/abdda9f1-bf18-4a33-81d2-06080e806f5e.jpeg?im_w=720" 
        }],
        appartment_description: 'This small house sleeping up to 7 people is located in the former stable of Napoleon Bonaparte. It consists of two spacious bedrooms, a corner for a single bed as well as a sofa bed in the living room. You will also have an outside corner to enjoy the calm but also to be able to admire the horses.',
        appartment_price_per_night: '120 Euros',
        discount_description: '',
        appartment_air_bnb_link:'https://www.airbnb.com/rooms/50504072/photos?source_impression_id=p3_1630357023_A3ktctorsz2KslB6&guests=1&adults=1',
        appartment_elevation:'6'


      },
      {
        appartment_title: 'Appartement « Royal »',
        appartment_images: [{
          src: 'https://a0.muscache.com/im/pictures/miso/Hosting-50212638/original/1b92761d-7a72-4eb2-8138-325673404019.jpeg?im_w=1200'
        }, {
          src: 'https://a0.muscache.com/im/pictures/miso/Hosting-50212638/original/5518e61d-fe5a-48dc-bbfc-52766b05dabc.jpeg?im_w=720'
        }, { 
          src: 'https://a0.muscache.com/im/pictures/miso/Hosting-50212638/original/26710a42-2160-4f60-b27e-69fb90e4da9e.jpeg?im_w=720' 
        },
        { 
          src: 'https://a0.muscache.com/im/pictures/miso/Hosting-50212638/original/26710a42-2160-4f60-b27e-69fb90e4da9e.jpeg?im_w=720' 
        }],
        appartment_description: 'This small apartment is located in the former stable of Napoleon Bonaparte, in the middle of nature and not far from the breeding of horses. You will have a spacious outdoor area, with a barbecue at your disposal, in order to enjoy absolute calm.',
        appartment_price_per_night: '120 Euros',
        discount_description: '',
        appartment_air_bnb_link:'https://www.airbnb.com/rooms/50212638?check_in=2021-09-15&check_out=2021-09-16&translate_ugc=true&federated_search_id=86940a8a-2d7a-4b99-b6bb-ff8e2574d776&source_impression_id=p3_1630355877_mC%2FZc20bfExB9leC&guests=1&adults=1',
        appartment_elevation:'6'
      },{
        appartment_title: 'Appartement ZO',
        appartment_images: [{
          src: 'https://a0.muscache.com/im/pictures/miso/Hosting-51363203/original/0a7c0df3-b030-4e47-b8fa-18f330678031.jpeg?im_w=1200'
        }, {
          src: 'https://a0.muscache.com/im/pictures/miso/Hosting-51363203/original/a1dca45c-db41-4451-aa37-fb03ce46ed8e.jpeg?im_w=720'
        }, { 
          src: 'https://a0.muscache.com/im/pictures/miso/Hosting-51363203/original/d3a11f07-c814-494f-acf2-5d619926c969.jpeg?im_w=720' 
        }, { 
          src: 'https://a0.muscache.com/im/pictures/miso/Hosting-50212638/original/978af474-cd84-461b-b763-144edf3b9016.jpeg?im_w=720' 
        }, { 
          src: 'https://a0.muscache.com/im/pictures/miso/Hosting-50212638/original/26710a42-2160-4f60-b27e-69fb90e4da9e.jpeg?im_w=720' 
        }],
        appartment_description: 'This small apartment is located in the former stable of Napoleon Bonaparte, in the middle of nature and not far from the breeding of horses. You will have a spacious outdoor area, with a barbecue at your disposal, in order to enjoy absolute calm.',
        appartment_price_per_night: '120 Euros',
        discount_description: '',
        appartment_air_bnb_link:'https://www.airbnb.com/rooms/50212638?check_in=2021-09-15&check_out=2021-09-16&translate_ugc=true&federated_search_id=86940a8a-2d7a-4b99-b6bb-ff8e2574d776&source_impression_id=p3_1630355877_mC%2FZc20bfExB9leC&guests=1&adults=1',
        appartment_elevation:'6'

      },{
        appartment_title: 'Appartement « Cardo »',
        appartment_images: [{
          src: 'https://a0.muscache.com/im/pictures/miso/Hosting-50503844/original/777cdb3e-7414-46fb-ad91-8927a094e33d.jpeg?im_w=1200'
        }, {
          src: 'https://a0.muscache.com/im/pictures/miso/Hosting-50503844/original/960ae642-8abc-4dd3-90fd-39d4fd8a5447.jpeg?im_w=720'
        }, { 
          src: 'https://a0.muscache.com/im/pictures/miso/Hosting-50503844/original/a7d91fd6-a042-4c95-bf31-02a1bcab19ee.jpeg?im_w=720' 
        }, { 
          src: 'https://a0.muscache.com/im/pictures/miso/Hosting-50503844/original/d72793fc-83ea-4207-bbe0-fea366d625c0.jpeg?im_w=720' 
        }, { 
          src: 'https://a0.muscache.com/im/pictures/miso/Hosting-50503844/original/0b8add99-94bf-444e-a85a-d796b850a431.jpeg?im_w=720' 
        }],
        appartment_description: 'Relax in this pretty little apartment that s located in Napoleon Bonaparte s old stable. You will enjoy absolute calm with an outside to share a barbecue, not far from the horses.',
        appartment_price_per_night: '120 Euros',
        discount_description: '',
        appartment_air_bnb_link:'https://www.airbnb.com/rooms/50212638?check_in=2021-09-15&check_out=2021-09-16&translate_ugc=true&federated_search_id=86940a8a-2d7a-4b99-b6bb-ff8e2574d776&source_impression_id=p3_1630355877_mC%2FZc20bfExB9leC&guests=1&adults=1',
        appartment_elevation:'6'

      },{
        appartment_title: 'Appartement Coco',
        appartment_images: [{
          src: 'https://a0.muscache.com/im/pictures/miso/Hosting-51362284/original/529ae721-7b0f-4466-b4d9-8a4ecc785e39.jpeg?im_w=1200'
        }, {
          src: 'https://a0.muscache.com/im/pictures/miso/Hosting-51362284/original/92f27316-5700-4090-9841-222198aaede7.jpeg?im_w=1200'
        }, { 
          src: 'https://a0.muscache.com/im/pictures/miso/Hosting-51362284/original/d55be8b3-6810-43f7-9dfa-5b75b006873b.jpeg?im_w=720' 
        }, { 
          src: 'https://a0.muscache.com/im/pictures/miso/Hosting-51362284/original/7980f83d-9245-4c2d-9c9e-c0aabcf9a4df.jpeg?im_w=720' 
        }, { 
          src: 'https://a0.muscache.com/im/pictures/miso/Hosting-50503844/original/0b8add99-94bf-444e-a85a-d796b850a431.jpeg?im_w=720' 
        }],
        appartment_description: 'Relax in this pretty little apartment that s located in Napoleon Bonaparte s old stable. You will enjoy absolute calm with an outside to share a barbecue, not far from the horses.',
        appartment_price_per_night: '120 Euros',
        discount_description: '',
        appartment_air_bnb_link:'https://www.airbnb.com/rooms/50212638?check_in=2021-09-15&check_out=2021-09-16&translate_ugc=true&federated_search_id=86940a8a-2d7a-4b99-b6bb-ff8e2574d776&source_impression_id=p3_1630355877_mC%2FZc20bfExB9leC&guests=1&adults=1',
        appartment_elevation:'6'

      },
    ]

  }),
}
</script>
