<template>
    <div>
        <transition name="fade">

        <v-card class="pension" style=" box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);" :min-height="pension_2.card_height" :class="pension_2.card_classes">
            <v-img height="12rem" width="100%" :src="pension_2.pension_img_url">

            </v-img>
            <v-card-title class="text-center">
                {{pension_2.pension_title}}
            </v-card-title>
            <v-card-text class="text-left">
                {{pension_2.pension_description}}
            </v-card-text>
            <v-card-subtitle style="position: absolute; bottom:0; right:0; left:0;margin-right:auto; margin-left:auto;" class="red--text font-weight-bold mx-auto">
                {{pension_2.pension_price_per_night}}
            </v-card-subtitle>
         
        </v-card>
        </transition>
    </div>
</template>
<script>
export default {
    name: 'pension',
    props: {
        pension_2: Object
    }
}
</script>
<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.pension:hover {
  transform: scale(0.81);
}

</style>