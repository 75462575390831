<template>
  <v-container>
    <v-row justify="center" class="text-center">
    

      

      <v-col
        class="my-5"
        cols="12"
      >
        <h2 class="gold--text">Stables</h2>
      </v-col>

      <v-col  v-for="appart in boxs" :key="appart.box_title"  cols="6" sm="4" md="4">
      
      <horse-box :box="appart"></horse-box>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HorseBox from './HorseBox.vue'
import stallion from '@/assets/wooden.jpeg'
import clock from '@/assets/clock1.jpg'
import wooden from '@/assets/images_web/wooden.jpg'
import chapel from '@/assets/images_web/20160410_1811.jpeg'
import villate from '@/assets/villate_s.jpeg'
import fr from '@/assets/images_web/fr.jpeg'

  export default {
    name: 'appartments-for-rent',
    components: {
      HorseBox
    },

    data: () => ({
      boxs:[
        {
          box_title: 'Stallion Stables',
          box_img_url: stallion,
          box_quantity:'12',
          box_description:'4x4m',
          box_price_per_night: '450 Euros',

        }, {
          box_title: 'Wooden Stables',
          box_img_url: wooden,
          box_quantity:'12',
          box_description:'3.8x3m',

          box_price_per_night: '450 Euros',

        },{
          box_title: 'Clock Stables',
          box_img_url: clock,
          box_description:'4.9x3.3m',

          box_quantity:'18',
          box_price_per_night: '450 Euros',

        },{
          box_title: 'Chapel Stables',
          box_description:'4.9x4m',

          box_img_url: chapel,
          box_quantity:'7',
          box_price_per_night: '450 Euros',

        },{
          box_title: 'Classic Villate Stables',
          box_description:'4x3.8m',

          box_img_url: villate,
          box_quantity:'24',
          box_price_per_night: '450 Euros',

        },{
          box_title: 'F.R Stables',
          box_img_url: fr,
          box_description:'3.5x3.5m',
          box_quantity:'20',
          box_price_per_night: '450 Euros',

        }
      ]
      
    }),
  }
</script>
