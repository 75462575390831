<template>
  <v-container class="my-0">
    <v-row class="text-center my-2 height-s"  >
    

      

      <v-col
        class="my-5"
        cols="12"
      >
        <h2 class="gold--text">Clinic Packages</h2>
        <p class="text-center">
           
          Clinic Packages are adjusted to related rider and his level of riding and education.
        </p>
      </v-col>

      <v-col v-for="appart in packages" :key="appart.package_title" cols="12" sm="6" md="6">
      
      <package :package_2="appart"></package>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Package from './Package.vue'
import training1 from "@/assets/jumping1.jpeg";
import training2 from "@/assets/15days.jpeg";
import training3 from "@/assets/images_web/training.jpeg";
import tarining4 from "@/assets/images_web/tarining4.jpg";
  export default {
    name: 'packages',
    components: {
      Package
    },

    data: () => ({
      packages:[
        {
          package_title: 'One Week',
          package_img_url: training2,
          package_description:'1 week of training including accomodation, pension horses, top trainers and  competing in shows',
          package_price_per_night: '1000 Euros',
          dialog: false

        }, {
          package_title: '15 days',
          package_img_url: training3,
          package_description:'15 days of training including accomodation, pension horses, top trainers and  competing in shows',
          package_price_per_night: '1500 Euros',
          dialog: false

        },{
          package_title: '3 weeks',
          package_img_url: training1,
          package_description:'3 weeks of training including accomodation, pension horses, top trainers and  competing in shows',
          package_price_per_night: '2250 Euros',
          dialog: false

        },{
          package_title: '1 month',
          package_img_url: tarining4,
          package_description:'1 month of training including accomodation, pension horses, top trainers and  competing in shows',
          package_price_per_night: '3375 Euros',
          dialog: false

        },
      ]
      
    }),
  }
</script>
<style>

.height-s{
  height:81vh
}
@media only screen and (max-width: 981px) {
  .height-s {

    height: auto;
   

  }
}

  </style>