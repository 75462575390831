<template>
  <div>
    <v-container>
      <v-row>
      <v-col cols="12">
        <Video :source="video_ch"></Video>
      </v-col>
    </v-row>
    </v-container>
    

    <v-row no-gutters>
      <v-col cols="12" >
        <Chateau></Chateau>
      </v-col>
    </v-row>
    <v-row class="home2">
      <v-col cols="12">
        <Horses></Horses>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-0" cols="12">
        <v-carousel class :show-arrows="false">
          <v-carousel-item v-for="(item,i) in items" :key="i" :src="item.src"></v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
    
    <v-row class="home3">
        <v-col md="6" cols="12">
          <Packages></Packages>
        </v-col>
        <v-col class="pa-0" md="6" cols="12">
          <YoutubeVue
            ref="youtube1"
            height="96%"
            width="100%"
            autoplay="1"
            :muted="1"
            :videoid="play.video_id"
            :loop="play.loop"
          />
        </v-col>
      </v-row>

    <div class="home2"></div>

    <div >
     
    </div>
    <v-row class="home2">
      <v-col cols="12">
        <Pensions></Pensions>
      </v-col>
    </v-row>
    <div class="home2">
      <div class="pr">
       
      </div>
    </div>
    <v-row no-gutters>
      <v-col cols="12" md="6" class="my-auto">
        <horse-boxes />
      </v-col>
      <v-col cols="12" md="6">
        <v-img :src="zo1"></v-img>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="home2">
        <HorseBreeding></HorseBreeding>
      </v-col>
      <v-col cols="12" >
        <AppartmentsForRent></AppartmentsForRent>
      </v-col>
    </v-row>
  
    <v-img :src="joy"></v-img>
    <v-footer dark padless>
      <v-card flat tile class="black lighten-1 gold--text text-center">
        <v-card-text>
          <v-btn
            v-for="icon in icons"
            :key="icon.icon"
            class="mx-4 gold--text"
            icon
            :href="icon.src"
            target="_blank"
          >
            <v-icon size="24px">{{ icon.icon }}</v-icon>
          </v-btn>
        </v-card-text>

        <v-card-text class="gold--text pt-0" style="width:100vw"></v-card-text>

        <v-divider></v-divider>

        <v-card-text class="gold--text">
          {{ new Date().getFullYear() }} —
          <strong>chateau de la riviere</strong>
          <br />
          <small>For more info please contact us on: joynajem@hotmail.com , +33 7 666 88888</small>
        </v-card-text>
      </v-card>
    </v-footer>
  </div>
</template>

<script>
import Video from '../components/Video'
import HorseBreeding from '../components/Horses/HorseBreeding'
import AppartmentsForRent from '../components/AppartmentsForRent'
import Horses from '../components/Horses/Horses'
import Chateau from '../components/Chateau'
import HorseBoxes from '../components/HorseBoxes/HorseBoxes.vue'
import Packages from '../components/Packages/Packages.vue'
import Pensions from '../components/Pensions/Pensions.vue'
//import parallax2 from '@/assets/DSC_0228.jpg'

//import parallax3 from '@/assets/DSC_0325_1.jpg'
import parallax4 from '@/assets/summer5.jpeg'
import parallax5 from '@/assets/20160410_2351.jpg'
import parallax6 from '@/assets/caroussel1.jpeg'
import parallax7 from '@/assets/car2.jpeg'
import clock1 from '@/assets/clock1.jpg'
import winter1 from '@/assets/winter1.jpeg'
import winter2 from '@/assets/winter3.jpeg'
import winter3 from '@/assets/winter4.jpeg'
import winter4 from '@/assets/winter6.jpeg'
import zo1 from '@/assets/Zoe.jpeg'
import joy from '@/assets/Joy.jpeg'
import video_ch from '@/assets/Chateau del la riviere_1.mp4'

import { YoutubeVue } from 'youtube-vue'

export default {
  name: 'Home',
  data() {
    return {
      video_ch: video_ch,
      play: { video_id: "ZhMzyrVOJ-s", loop: 1 },
      play_1: { video_id: "QbAw8J1yIDM", loop: 1 },
      parallax: parallax5,
      zo1: zo1,
      joy: joy,
      icons: [{

        icon: 'mdi-facebook',
        src: 'https://www.facebook.com/jumenterienational'
      }, {

        icon: 'mdi-linkedin',
        src: 'https://www.linkedin.com/in/joy-najem-92b188188/'
      }, {

        icon: 'mdi-instagram',
        src: 'https://www.instagram.com/chateau_de_la_riviere_/'
      }
      ],
      items: [

        {
          src: clock1,
        }, {
          src: parallax6,
        }, {
          src: parallax7,
        }, {
          src: winter1,
        }, {
          src: winter2,
        }, {
          src: winter3,
        }, {
          src: winter4,
        }, {
          src: parallax4,
        },
      ],
    }
  },
  components: {
    Video,
    HorseBreeding,
    AppartmentsForRent,
    Horses,
    Chateau,
    HorseBoxes,
    Packages,
    Pensions,
    YoutubeVue,

  },
  metaInfo() {
    return {
      title: 'Chateau De La Riviere - Jumenterie National ',
      // override the parent template and just use the above title only
      meta: [
        { name: 'description', content: ' Chateau de la Riviere is renowned to offer visitors a pristine environment top relax and indulge in exquisite horse back riding.' },
        { property: 'og:site_name', content: "Chateau De La Riviere" },
        { property: 'og:title', content: "Chateau De La Riviere - Jumenterie National " },
        { property: 'title', content: "Chateau De La Riviere - Jumenterie National " },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' }
      ]
    }

  },
  mounted() {
    this.$refs.youtube.player.mute();
  }
}
</script>
<style>
.home {
  background-color: #e4e4e4;
  background-repeat: repeat;
  background-position: top, left;
  background-size: cover;
  backdrop-filter: blur(2px);

  background-attachment: fixed;

  overflow: hidden;
}
.home1 {
  background-color: #e9e9e9;
  background-repeat: repeat;
  background-position: top, left;
  background-size: cover;

  background-attachment: fixed;

  overflow: hidden;
}
.home2 {
  background-color: #e6e6e6;
  background-repeat: repeat;
  background-position: top, left;
  background-size: cover;

  background-attachment: fixed;

  overflow: hidden;
}
.home3 {
  background-color: #c6c6c6;
  background-repeat: repeat;
  background-position: top, left;
  background-size: cover;

  background-attachment: fixed;

  overflow: hidden;
}

.bg-image {
  /* The image used */
  background-color: rgba(255, 255, 255, 0.27);
  /* Add the blur effect */

  /* Full height */
  height: 20%;
  width: 96%;
  filter: blur(3px);
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>