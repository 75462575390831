<template>
    <div   >
        
        <my-video   ref="videoRef" style="box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);" :sources="video.sources" :options="video.options"></my-video>
        
    </div>
</template>
<script>
import video_ch from '@/assets/Chateau del la riviere_1.mp4'
import myVideo from 'vue-video'

export default {
    name: 'video',
    components:{
        myVideo
    },
    props:{
        source: String
    },
    data(){
        return {
            src: video_ch,
            video: {
                sources: [{
                    src: video_ch,
                    type: 'video/mp4'
                }],
                options: {
                    autoplay: true,
                    volume: 0,
                }
        }
    }
    },
    mounted(){
        this.$refs.videoRef.play();
    }
}
</script>