<template>
  <v-app>
    <v-app-bar
    app color="transparent"
      
    
      fixed
      elevation="0"
            top
    >    

        <v-img
          alt="Vuetify Logo"
          class="shrink ma-2 pa-2"
          contain
          :src="log"
          transition="scale-transition"
          width="144" style="height:100vh"
        />     


      <v-spacer></v-spacer>

<v-spacer></v-spacer>


     
    </v-app-bar>
   
    <v-main class=" back_lin" >
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import logo_chateau from '@/assets/logo_chateau_small.png'
import hero from '@/assets/20160410_2141.jpg'
import video from '@/assets/Chateau del la riviere_1.mp4'
export default {
  name: 'App',

  data: () => ({
    log:logo_chateau,
    collapseOnScroll: true,
    parralax: hero,
    video: video,
    starting: "1"
    //
  }),
  methods:{
        callFunction: function () {
            setTimeout(function () {
              this.starting = "2"
            }, 3000);
        }
    },
    mounted () {
      this.callFunction()
    }
};
</script>
<style lang="scss">
 @import './scss/variable.scss';
 
 @import url('https://fonts.googleapis.com/css2?family=Alegreya+SC&family=Mate+SC&display=swap');
 
 $body-font-family:    'Alegreya SC', serif!important;
.v-application{
  font-family: 'Alegreya SC', serif!important;
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.menu{
  font-style: italic;
  font-weight: 200;

}
.back_lin{
  background: linear-gradient(-45deg, #232526,#414345);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}

</style>