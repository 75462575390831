<template>
  <v-container>
    <v-row class="text-center">
    

      

      <v-col
        class="my-5"
        cols="12"
      >
        <h2 class="gold--text">Pensions</h2>
      </v-col>

      <v-col class="my-auto" v-for="appart in pensions" :key="appart.pension_title" :sm="appart.cols" cols="12" md="4">
      
      <pension :pension_2="appart"></pension>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Pension from './Pension.vue'
import standard from "@/assets/cover.jpeg";
import paddock from "@/assets/images_web/20160410_2188.jpeg";
import superior from "@/assets/glock2.png";
import back from "@/assets/images_web/DSC_0275.jpeg";
import working from "@/assets/working.jpeg";
import daily from "@/assets/daily.jpeg";
  export default {
    name: 'pensions',
    components: {
      Pension
    },

    data: () => ({
      pensions:[
        {
          pension_title: 'Standard',
          pension_img_url: standard,
          pension_description:'Box, Hay, Straw, Feed',
          pension_price_per_night: '550 Euros',
          card_height: '21rem',
          card_classes: 'my-auto',
          cols: '8'

        }, {
          pension_title: 'Working',
          pension_img_url: working,
          pension_description:'Box, Hay, Straw, Feed, Training',
          pension_price_per_night: '900 Euros',
          card_height: '24rem',
          cols: '4'


        },{
          pension_title: 'Superior Working',
          pension_img_url: superior,
          pension_description:'Box, Hay, Straw, Feed, Training, Paddock',
          pension_price_per_night: '1100 Euros',
          card_height: '27rem',
          cols: '6'

        },{
          pension_title: 'Back On Track',
          pension_img_url: back,
          pension_description:'Box, Hay, Straw, Feed, Training, Paddock, HydroTherapy, Neccessary Care, ',
          pension_price_per_night: '1500 Euros',
          card_height: '27rem',
          cols: '6'


        },{
          pension_title: 'Paddock',
          pension_img_url: paddock,
          pension_description:' Hay, Paddock',
          pension_price_per_night: '350 Euros',
          card_height: '24rem',
          cols: '4'


        },{
          pension_title: 'Daily',
          pension_img_url: daily,
          pension_description:'Box, Hay, Straw',
          pension_price_per_night: '30 Euros',
          card_height: '21rem',
          cols: '8'

        },
      ]
      
    }),
  }
</script>
