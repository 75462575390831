<template>
<v-container >
    <v-row class="  bg-primary"  style="height:90vh"   >
<v-col cols="12" align-self="center">


        <h1 class="text-center my-6 gold--text font-weight-thin"> Breeding</h1>
        <p class="text-center">
            Our brood mares have been passed on to us from a few of the best breeders in Europe. Including blood lines which are renowned  world wide for being champions in the show jumping sport.
        <br>
        <small >For more info please contact Pierrick Zoeller on: +33 6 47 72 50 86 </small>
        </p>
      
        <v-tabs v-model="tab" background-color="transparent" color="gold" grow justify="center">
            <v-tab v-for="item in items" :key="item">{{ item }}</v-tab>
        </v-tabs>

        <v-tabs-items class="" style="background-color:transparent" v-model="tab">
            <v-tab-item   v-for="item in items" :key="item">
                <v-row v-if="tab === 0" class="my-3">
                    <v-col class="" v-for="horse_s in horses_stall" :key="horse_s.horse_title" md="4" cols="12">
                        <horse :horse="horse_s"></horse>
                    </v-col>
                </v-row> <v-row v-if="tab === 1">
                    <v-col v-for="horse_m in horses_mares" :key="horse_m.horse_title" md="4" cols="12">
                        <horse :horse="horse_m"></horse>
                    </v-col>
                </v-row> 
            </v-tab-item>
        </v-tabs-items>
    </v-col>
    </v-row>
</v-container>
  
</template>
<script>
import Horse from './Horse'
import parallax from '@/assets/glock2.png'
import parallax1 from '@/assets/2MV_5194.jpg'
import glock2 from '@/assets/glock2.png'
import wivo from '@/assets/Noeuf.jpg'

export default {
    name: 'horses',
    components: {
        Horse
    },
    data() {
        return {
            tab: null,

            items: [
                'Stallions', 'Mares',
            ],
            horses_stall: [{
                horse_title: 'Untouchable M',
                horse_img_url: glock2,
                horse_description: 'Top stallion from Quick Star X Corrado ',
                horse_price_per_night: '450 Euros',
                dialog: false

            },{
                horse_title: 'Neuf Decoeur Tardonne',
                horse_img_url: wivo,
                horse_description: 'La force, le talent, la souplesse, la générosité, le courage',
                horse_price_per_night: '450 Euros',
                dialog: false

            }],
            horses_mares: [{
                horse_title: 'Zokarla',
                horse_img_url: parallax1,
                horse_description: 'Smart and Sensitive Mare with lots of Blood and Higher Self.',
                horse_price_per_night: '450 Euros',
                dialog: false

            },],
            horses_foals: [{
                horse_title: 'Dany2',
                horse_img_url: parallax,
                horse_description: 'The Legend of all horses',
                horse_price_per_night: '450 Euros',

            },{
                horse_title: 'Untouchable',
                horse_img_url: parallax,
                horse_description: 'The Legend of all horses',
                horse_price_per_night: '450 Euros',

            },{
                horse_title: 'Untouchable',
                horse_img_url: parallax,
                horse_description: 'The Legend of all horses',
                horse_price_per_night: '450 Euros',

            },{
                horse_title: 'Untouchable',
                horse_img_url: parallax,
                horse_description: 'The Legend of all horses',
                horse_price_per_night: '450 Euros',

            },],
            
        }
    }
}
</script>
