<template>
<v-container >
    <v-row class="  bg-primary"  style="height:90vh"   >
<v-col cols="12" align-self="center">


        <h1 class="text-center my-6 gold--text font-weight-thin"> A Home for Champions</h1>
        <p class="text-center">
            We Believe in the Harmony between a rider and his horse. This is why we aim always to fit a rider to his equivilant horse. Our dedication in training horses is what makes these horses bond with their riders. We do no harm is our motto while training, we belive that horses do things based on reason and that is why we treat the horses as partners and equals.
        </p>
        <v-tabs v-model="tab" background-color="transparent" color="gold" grow justify="center">
            <v-tab v-for="item in items" :key="item">{{ item }}</v-tab>
        </v-tabs>

        <v-tabs-items class="" style="background-color:transparent" v-model="tab">
            <v-tab-item   v-for="item in items" :key="item">
                <v-row v-if="tab === 0" class="my-3">
                    <v-col class="" v-for="horse_s in horses_stall" :key="horse_s.horse_title" md="4" cols="12">
                        <horse :horse="horse_s"></horse>
                    </v-col>
                </v-row> <v-row v-if="tab === 1">
                    <v-col v-for="horse_m in horses_mares" :key="horse_m.horse_title" md="4" cols="12">
                        <horse :horse="horse_m"></horse>
                    </v-col>
                </v-row> 
            </v-tab-item>
        </v-tabs-items>
    </v-col>
    </v-row>
</v-container>
  
</template>
<script>
import Horse from './Horse'
import parallax from '@/assets/glock2.png'
import parallax1 from '@/assets/2MV_5194.jpg'
import wivo from '@/assets/wivo2.jpg'

export default {
    name: 'horses',
    components: {
        Horse
    },
    data() {
        return {
            tab: null,

            items: [
                'Top Quality Horses', 'Young Horses',
            ],
            horses_stall: [{
                horse_title: 'Lennox',
                horse_img_url: 'https://media.gettyimages.com/photos/luciana-diniz-of-portugal-and-lennox-compete-in-the-csi5-global-tour-picture-id144789104',
                horse_description: 'Partner Luciana Diniz',
                horse_price_per_night: '450 Euros',
                dialog: false

            },{
                horse_title: 'Wiveau',
                horse_img_url: wivo,
                horse_description: 'Partner Alain Juffer',
                horse_price_per_night: '450 Euros',
                dialog: false

            }],
            horses_mares: [{
                horse_title: 'Cyrana',
                horse_img_url: parallax1,
                horse_description: 'The Legend of all horses',
                horse_price_per_night: '450 Euros',
                dialog: false

            },],
            horses_foals: [{
                horse_title: 'Dany2',
                horse_img_url: parallax,
                horse_description: 'The Legend of all horses',
                horse_price_per_night: '450 Euros',

            },{
                horse_title: 'Untouchable',
                horse_img_url: parallax,
                horse_description: 'The Legend of all horses',
                horse_price_per_night: '450 Euros',

            },{
                horse_title: 'Untouchable',
                horse_img_url: parallax,
                horse_description: 'The Legend of all horses',
                horse_price_per_night: '450 Euros',

            },{
                horse_title: 'Untouchable',
                horse_img_url: parallax,
                horse_description: 'The Legend of all horses',
                horse_price_per_night: '450 Euros',

            },],
            
        }
    }
}
</script>
