<template>
  <div>
    <v-card
      class="img-card"
      @click="package_2.dialog = true"
      style="background-color:rgba(255,255,255,0.63);  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);"
    >
      <v-row no-gutters>
        <v-col cols="6">
          <v-img height="9rem" width="12rem" :src="package_2.package_img_url"></v-img>
        </v-col>
        <v-col cols="6" class="my-auto">
          <v-card-subtitle class="text-center">
            <h3>{{ package_2.package_title }}</h3>
          </v-card-subtitle>

          <v-card-subtitle class="red--text caption"></v-card-subtitle>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog max-width="720" v-model="package_2.dialog">
      <v-card>
        <v-card-title>Reserve Your Package</v-card-title>
        <v-card-subtitle>{{ package_2.package_title }} - Full Package</v-card-subtitle>
        <v-card-text class="gold--text">{{ package_2.package_description }}</v-card-text>
        <v-card-text>To Reserve your Spot, Please fill out the form and our team will get back to you!!!</v-card-text>
        <v-card-text>
          <form id="form1" @submit.prevent="sendEmail">

          <v-row>
            <v-col cols="6">
              <input type="hidden" name="package_title" :value="package_2.package_title"/>
              <v-text-field label="First Name" name="first_name" v-model="form.first_name" required></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Last Name" name="last_name" v-model="form.last_name" required></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Country" name="country" v-model="form.country" required></v-text-field>
              <v-text-field label="Number of Riders" name="riders_count" v-model="form.riders_count" required></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="email address" name="email_address" v-model="form.email_address" required></v-text-field>
              <v-text-field label="Phone Number" name="phone_number" v-model="form.phone_number" required></v-text-field>
            </v-col>
            <v-col cols="12"><v-btn color="gold" block class-="primary">
              <input type="submit" class="btn white--text" value="Send">

            </v-btn>

              {{message}}
            </v-col>
          </v-row>
          </form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import emailjs from 'emailjs-com';
export default {
  name: 'package',
  props: {
    package_2: Object
  },
  data() {
    return {
      message:'',
      form: {
        first_name: '',
        last_name: '',
        country: '',
        email_address: '',
        phone_number: '',
        riders_count: ''
      }
    }
  },
  methods: {
    sendEmail(e) {
      try {
        emailjs.sendForm('service_fdmezzd', 'package_form', e.target, 'user_7S6BEtpSJXlpASSB4t5zT', {
          first_name: this.form.first_name,
          last_name: this.form.last_name,
          phone_number: this.form.phone_number,
          riders_count: this.form.riders_count,
          country: this.form.country,
          email_address: this.form.email_address,
          package_title: this.package_2.package_title
        })
        console.log('it works!!!')
        this.message = "Info Sent"
      } catch (error) {
        console.log({ error })
      }
      // Reset form field
      this.name = ''
      this.email = ''
      this.message = ''
    },
  }
}
</script>
<style>
.img-card:hover {
  transform: scale(0.9);
}
</style>