<template>
    <div>
        <v-card class="horse-card" @click="horse.dialog = true">
            <v-row no-gutters>
              
                <v-col cols="8">
                    <v-card-title class="h5">
                {{horse.horse_title}}
            </v-card-title>
            <v-card-text class="text-left">
                {{horse.horse_description}}
            </v-card-text>
                </v-col>
                <v-col cols="4" style="height: 9rem">
                    <v-img height="100%" width="100%" :src="horse.horse_img_url">

</v-img>
                </v-col>
            </v-row>
            
       
           
         
        </v-card>
        <v-dialog v-model="horse.dialog" max-width="600">
            <v-card>
                <v-row no-gutters >
              
              <v-col cols="8" >
                  <v-card-title class="h5">
              {{horse.horse_title}}
          </v-card-title>
          <v-card-text class="text-left">
              {{horse.horse_description}}
          </v-card-text>
              </v-col>
              <v-col cols="4" style="height: 9rem">
                  <v-img height="100%" width="100%" :src="horse.horse_img_url">

</v-img>
              </v-col>
          </v-row>
            </v-card>
           
        </v-dialog>
    </div>
</template>
<script>
export default {
    name: 'horse',
    props: {
        horse: Object
    }
}
</script>
<style>
.horse-card:hover{
    transform: scale(1.1);
    cursor:pointer;
}</style>